import { useQuery } from "react-query";
import { GetCourseV2Type } from "../../schemas/schema";
import { getCourseById, getCourses } from "../api/v2/courses";

export function useCourses() {
  return useQuery({
    queryKey: "getCourses",
    queryFn: async () => (await getCourses()) as GetCourseV2Type[],
    staleTime: 60000,
    retry: false, // Number of times to retry a failed query
    retryOnMount: false,

    refetchOnWindowFocus: false,
    enabled: true,
  });
}

export function useCourse({ course_id }: { course_id: string }) {
  return useQuery({
    queryKey: ["getCourse", course_id],
    queryFn: async () => {
      const course = await getCourseById({ course_id });
      return (course as unknown) as GetCourseV2Type;
    },
    staleTime: 60000,
    retry: false, // Number of times to retry a failed query
    retryOnMount: false,

    refetchOnWindowFocus: false,
    enabled: true,
  });
}