import type { SaveUserInfoInput } from "../../../schemas/schema";
import api from "../../axios";
export const GetIITEntranceExamStatus = async (courseId: string, test_date?: string) => {
    const client = await api.client;
    const response = await client.iit_entrance_status_check_v2_payment_record_iit_entrance_status_check_get({ course_id: courseId, test_date });
    return response.data;
};

export const GetOldCourseEnrollmentData = async (courseId: string) => {
    const client = await api.client;
    const response = await client.redirect_user_to_old_page_v2_payment_record_redirect_user_get({ course: courseId });
    return response.data;
};

export const GetIITEntranceOnboardingStatus = async (courseId: string) => {
    const client = await api.client;
    const response = await client.iit_onboarding_status_check_v2_payment_record_iit_onboarding_status_check_get({ course_id: courseId });
    return response.data;
};

export const SaveUserInfo = async (data: SaveUserInfoInput) => {
    const client = await api.client;
    const response = await client.iit_entrance_onboarding_user_info_v2_payment_record_iit_entrance_onboarding_user_info_post(null, data);
    return response.data;
}

export const GetIITEntranceExamStatusV2 = async (courseId: string, test_date?: string) => {
    const client = await api.client;
    const response = await client.iit_entrance_status_check_v2_payment_record_iit_entrance_status_check_v2_get({ course_id: courseId, test_date });
    return response.data;
};

export const PostCounsellingSessionFeedback = async ({ courseId, rating, feedback }: { courseId: string, rating: number, feedback: string }) => {
    const client = await api.client;
    const response = await client.counselling_session_feedback_v2_payment_record_counselling_session_feedback_put(null, { course_id: courseId, rating, feedback });
    return response.data;
}