import { Box, Flex, Text, Link } from "@chakra-ui/react";

import { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useLocation } from "react-router-dom";
import { TryArrow } from "../../../../assets/icons/components";
import useSnackBar from "../../../common/general/SnackBar";
import { sendTrackingEvent } from "../../../common/utils/gtm";

import DragAndDropSkeleton from "./DragAndDropSkeleton";

interface Props {
  type: "front" | "back";
  file?: File;
  setFile: (file: File) => void;
  handleImageDelete: () => void;
  toastMessage?: string;
  acceptedFileTypes?: string[];
}
const DragAndDrop = ({ file, setFile, handleImageDelete, type, toastMessage, acceptedFileTypes }: Props) => {
  const fileTypes = acceptedFileTypes || ["JPEG", "PNG", "JPG"];
  const [errorMessage, setErrorMessage] = useState("");
  const snackbar = useSnackBar();
  const onTypeError = () => {
    setErrorMessage("Please upload only image files of type JPEG, PNG or JPG");
  };
  const onSizeError = () => {
    setErrorMessage("Image size should be less than equal to 50MB");
  };
  const location = useLocation();
  return (
    <Box w={"full"}>
      {file ? (
        <DragAndDropSkeleton text={file.name} onClick={handleImageDelete} />
      ) : (
        <FileUploader
          handleChange={(file: File) => {
            let message = "";
            if (type === "front") {
              message = toastMessage || "Image has been uploaded";
            } else if (type === "back") {
              message = toastMessage || "Image has been uploaded";
            }
            snackbar.success(message);
            setErrorMessage("");
            console.log("file", file);
            setFile(file);
          }}
          name="file"
          types={fileTypes}
          maxSize={50}
          onTypeError={onTypeError}
          onSizeError={onSizeError}
        >
          <Box
            onClick={() => {
              sendTrackingEvent({
                event: type === "front" ? "d_onboarding_browse_f" : "d_onboarding_browse_b",
                eventLabel: location.pathname,
              });
            }}
            minW={{ base: "283px", md: "492px" }}
            cursor={"pointer"}
            w={"full"}
          >
            <Flex borderRadius={"ms-8"} border="1px dashed #B3B3B3" bgColor={"#FBFBFB"} alignItems={"center"} justifyContent={"center"} h={"48px"}>
              <TryArrow color="ms-green.400" boxSize={"6"} />
              <Text ml={"ms-10"} textStyle={"caption"}>
                Drag & drop here or{" "}
                <Link>
                  <Text d={"inline"} textStyle={"caption"} color={"ms-blue.500"}>
                    Browse
                  </Text>
                </Link>
              </Text>
            </Flex>
          </Box>
          <Text mt={"ms-8"} textStyle={"body2"} color={"ms-error"}>
            {errorMessage}
          </Text>
        </FileUploader>
      )}
    </Box>
  );
};

export default DragAndDrop;
