import { Box } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import WelcomeBannerV2 from "./WelcomeBannerV2";
import StillHaveQuestions from "./StillHaveQuestions";
import { ACTIVITY_TYPE } from "../../pages/V2/Dashboard";
import ApplicationProgressCard from "./ApplicationProgressCard";
import { ProgramProfileType } from "../../enums/ProgramType.enum";
import ActivityCardContainerV2 from "./activitiesV2/ActivityCardContainerV2";
import SelectCoursePreferencePage, { CourseType } from "./SelectCoursePreferencePage";
import { CourseDataInterface, iitGCourseData, iitMandiCourseData } from "../../constants";
import { ApplicationType, ProfileAssessment, ProfileType } from "../../api/schemas/schema";
import { ApplicationProgressData, showApplicationProgressCardForIIT } from "../../utils/dashboard";
import { getProfile } from "../../api/utils/api/v2/profile";

interface Props {
  activeApplication: ApplicationType;
  profile: ProfileType;
  isPaymentDoneForIITG: boolean;
  isPaymentDoneForIITMandi: boolean;
  lastAssessment: ProfileAssessment;
  isRegisterationPaymentDone: boolean;
  shouldShowDetailsModal: boolean;
}

const IITDashboard = ({ activeApplication, profile, isRegisterationPaymentDone, isPaymentDoneForIITG, isPaymentDoneForIITMandi, lastAssessment }: Props) => {
  const history = useHistory();
  const [isProgressCardVisible, setIsProgressCardVisible] = useState(false);
  const [isApplicationCompleted, setIsApplicationCompleted] = useState(false);
  const [courseCardToShow, setCourseCardToShow] = useState<CourseDataInterface[] | null>(null);
  const [progressCardData, setProgressCardData] = useState<ApplicationProgressData | null>(null);
  const [userprofile, setProfile] = useState<ProfileType | null>(null);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const data = await getProfile();
        setProfile(data as ProfileType);
        if (data.program_profile_type === ProgramProfileType.MASAI || data.program_profile_type === ProgramProfileType.PREPLEAF) {
          history.push("/");
        } else {
          history.push("/allapplications");
        }
      } catch (error) {
        console.error("Failed to fetch profile:", error);
      }
    };
    //  console.log("iit dashboard");
    //  history.push("/allapplications");

    fetchProfile();
  }, []);

  useEffect(() => {
    if (profile) {
      const { data, showProgress } = showApplicationProgressCardForIIT(activeApplication, profile, isPaymentDoneForIITG, isPaymentDoneForIITMandi, lastAssessment, isRegisterationPaymentDone, history);
      setProgressCardData(data);
      setIsProgressCardVisible(showProgress);
      if (data) {
        setCourseCardToShow(data?.courseName === ProgramProfileType.IIT_GUWAHATI ? iitMandiCourseData : iitGCourseData);
      } else {
        setCourseCardToShow(null);
      }
    }
  }, [activeApplication, profile, lastAssessment, isPaymentDoneForIITG, isPaymentDoneForIITMandi, isRegisterationPaymentDone, history]);

  useEffect(() => {
    if (activeApplication) setIsApplicationCompleted(activeApplication?.status === "ONBOARDING_COMPLETE" ? true : false);
  }, [activeApplication]);

  const isIITGuwahati = profile && profile.program_profile_type === "IIT_GUWAHATI" ? true : false;

  return (
    <>
      <Box minH={"100vh"} height="100%" pt={"20px"}>
        <WelcomeBannerV2 />
        <Box mt="24px" display="flex" pr={{ xl: "80px" }} justifyContent="space-between" maxW="1440px" mx="auto" flexDir={{ base: "column", xl: "row" }}>
          <Box w={"full"}>
            {isProgressCardVisible && progressCardData && <ApplicationProgressCard data={progressCardData} isApplicationCompleted={isApplicationCompleted} isIITDashboard={true} />}
            {!isPaymentDoneForIITG && !isPaymentDoneForIITMandi && <SelectCoursePreferencePage selectedCourse={CourseType.IIT} isDashboard={false} coursesList={courseCardToShow} />}
          </Box>
          <Box maxW={{ xl: "330px" }} px={{ base: "16px", xl: "0px" }} w="full" display="flex" flexWrap="wrap" gap="10px">
            <ActivityCardContainerV2 onRegister={() => console.log("test")} isDashboard eventType={isIITGuwahati ? ACTIVITY_TYPE.IIT_COUNSELING : ACTIVITY_TYPE.IIT_MANDI_COUNSELING} />
            <StillHaveQuestions />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default IITDashboard;
