import React, { useState } from "react";
import { Box, Heading, Input, FormControl, FormLabel, FormErrorMessage, Button, Select } from "@chakra-ui/react";
import { useMutation } from "react-query";
import useSnackBar from "../common/general/SnackBar";
import { SaveUserInfo } from "../../api/utils/api/v2/IITEntranceExam";
import { axiosErrorHandler } from "../../api/utils/error";
import { useLocation } from "react-router-dom";
import { courDataDynamic } from "../EntranceExam/constents";
import { GetS3PresignedUrlType } from "../../api/schemas/schema";
import CustomUploadFileInput from "../V2/IITGOnboarding/CustomUploadFileInput";
import axios from "axios";

// Define an interface for the error messages
interface ErrorMessages {
  fullName?: string;
  currentLocation?: string;
  primaryContact?: string;
  parentContact?: string;
  parentName?: string;
  parentEmail?: string;
  passportPhoto?: string;
  graduationMonthYear?: string;
  tshirtSize?: string;
  address?: string;
  pincode?: string;
  townCity?: string;
  state?: string;
}

const OnboardingForm = ({ refetchIITOnboardingStatus }: { refetchIITOnboardingStatus: () => void }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const course: keyof ReturnType<typeof courDataDynamic> = searchParams.get("course") as keyof ReturnType<typeof courDataDynamic>;

  // State to hold form values
  interface FormValues {
    fullName: string;
    currentLocation: string;
    primaryContact: string;
    parentContact: string;
    parentName: string;
    parentEmail: string;
    passportPhoto: string;
    graduationMonthYear: string;
    tshirtSize: string;
    address: string;
    areaStreet: string;
    landmark: string;
    pincode: string;
    townCity: string;
    state: string;
  }

  const [formValues, setFormValues] = useState<FormValues>({
    fullName: "",
    currentLocation: "",
    primaryContact: "",
    parentContact: "",
    parentName: "",
    parentEmail: "",
    passportPhoto: "",
    graduationMonthYear: "",
    tshirtSize: "",
    address: "",
    areaStreet: "",
    landmark: "",
    pincode: "",
    townCity: "",
    state: "",
  });

  const [passportPhotoState, setPassportPhotoState] = useState<File | null>(null);

  // State to hold error messages for required fields
  const [errors, setErrors] = useState<ErrorMessages>({});

  const snackbar = useSnackBar();
  const { mutate: saveuserInfoMutate, isLoading: isSavinguserInfo } = useMutation(SaveUserInfo, {
    onSuccess: async () => {
      snackbar.success("Your information have been saved");
      refetchIITOnboardingStatus();
    },
    onError: (err) => {
      const e = axiosErrorHandler(err);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("something went wrong");
      }
    },
  });

  // Handle input changes
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target as HTMLInputElement;
    setFormValues({
      ...formValues,
      [name]: type === "checkbox" ? (e.target as HTMLInputElement).checked : value,
    });
  };

  // Validate required fields and set errors
  const validateForm = () => {
    const tempErrors: ErrorMessages = {};
    if (!formValues.fullName || formValues.fullName.trim() === "") tempErrors.fullName = "This field is required";
    if (!formValues.currentLocation || formValues.currentLocation.trim() === "") tempErrors.currentLocation = "This field is required";
    if (!formValues.primaryContact || formValues.primaryContact.trim() === "") {
      tempErrors.primaryContact = "This field is required";
    } else if (!/^\d{10}$/.test(formValues.primaryContact)) {
      tempErrors.primaryContact = "Contact number must be 10 digits";
    }
    if (!formValues.parentContact || formValues.parentContact.trim() === "") {
      tempErrors.parentContact = "This field is required";
    } else if (!/^\d{10}$/.test(formValues.parentContact)) {
      tempErrors.parentContact = "Contact number must be 10 digits";
    } else if (formValues.primaryContact === formValues.parentContact) {
      tempErrors.parentContact = "Parent's contact number must be different from primary contact number";
    }
    if (!formValues.parentName || formValues.parentName.trim() === "") tempErrors.parentName = "This field is required";
    if (!passportPhotoState || formValues.parentName.trim() === "") tempErrors.passportPhoto = "Passport size photo is required";
    if (!formValues.tshirtSize || formValues.tshirtSize.trim() === "") tempErrors.tshirtSize = "This field is required";
    if (!formValues.address || formValues.address.trim() === "") tempErrors.address = "This field is required";
    if (!formValues.pincode || formValues.pincode.trim() === "") {
      tempErrors.pincode = "This field is required";
    } else if (!/^\d+$/.test(formValues.pincode)) {
      tempErrors.pincode = "Pincode must be a number";
    }
    if (!formValues.townCity || formValues.townCity.trim() === "") tempErrors.townCity = "This field is required";
    if (!formValues.state || formValues.state.trim() === "") tempErrors.state = "This field is required";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = () => {
    if (validateForm()) {
      saveuserInfoMutate({
        course_id: course,
        fullName: formValues.fullName,
        currentLocation: formValues.currentLocation,
        primaryContact: formValues.primaryContact,
        parentContact: formValues.parentContact,
        parentName: formValues.parentName,
        parentEmail: formValues.parentEmail,
        graduationMonthYear: formValues.graduationMonthYear,
        tshirtSize: formValues.tshirtSize,
        address: formValues.address,
        areaStreet: formValues.areaStreet,
        landmark: formValues.landmark,
        pincode: formValues.pincode,
        townCity: formValues.townCity,
        state: formValues.state,
        passportPhoto: formValues.passportPhoto,
      });
    } else {
      console.log("Form validation failed");
    }
  };

  const handleFileUploadOnSuccess = async (presignedUrl: string, imageFile: File): Promise<string> => {
    const imageData = imageFile;
    const fileType = imageData as File;
    try {
      await axios.put(presignedUrl, imageData, {
        headers: {
          "Content-Type": fileType.type || "image/png",
        },
      });
      const imageUrl = presignedUrl.split("?")[0];
      return imageUrl;
    } catch (error) {
      console.error("Error uploading image:", error);
      return "";
    }
  };

  return (
    <>
      <Box borderRadius="14px" boxShadow={{ base: "none", md: "sm" }} p={{ base: "5px", md: "24px" }} border={{ base: "none", md: "1px solid #E5E5E5" }}>
        <Heading as="h6" mt={{ base: "0px" }}>
          We need some of your basic details for your admit
        </Heading>

        <FormControl isInvalid={!!errors.fullName} mt="24px">
          <FormLabel fontSize="14px" fontWeight="600">
            Full Name<span style={{ color: "red" }}>*</span>
          </FormLabel>
          <Input name="fullName" value={formValues.fullName || ""} onChange={handleInputChange} placeholder="Enter your Full Name" maxW="484px" h="48px" />
          <FormErrorMessage>{errors.fullName}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.currentLocation} mt="24px">
          <FormLabel fontSize="14px" fontWeight="600">
            Your Current Location<span style={{ color: "red" }}>*</span>
          </FormLabel>
          <Input name="currentLocation" value={formValues.currentLocation} onChange={handleInputChange} placeholder="Enter your Current Location" maxW="484px" h="48px" />
          <FormErrorMessage>{errors.currentLocation}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.primaryContact} mt="24px">
          <FormLabel fontSize="14px" fontWeight="600">
            Add primary contact number<span style={{ color: "red" }}>*</span>
          </FormLabel>
          <Input name="primaryContact" value={formValues.primaryContact} onChange={handleInputChange} placeholder="Enter primary contact number" maxW="484px" h="48px" />
          <FormErrorMessage>{errors.primaryContact}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.parentContact} mt="24px">
          <FormLabel fontSize="14px" fontWeight="600">
            Add {"parent's"} contact number<span style={{ color: "red" }}>*</span>
          </FormLabel>
          <Input name="parentContact" value={formValues.parentContact} onChange={handleInputChange} placeholder="Enter parent's contact number" maxW="484px" h="48px" />
          <FormErrorMessage>{errors.parentContact}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.parentName} mt="24px">
          <FormLabel fontSize="14px" fontWeight="600">
            Add {"parent's"} name<span style={{ color: "red" }}>*</span>
          </FormLabel>
          <Input name="parentName" value={formValues.parentName} onChange={handleInputChange} placeholder="Enter parent's name" maxW="484px" h="48px" />
          <FormErrorMessage>{errors.parentName}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.parentEmail} mt="24px">
          <FormLabel fontSize="14px" fontWeight="600">
            Add {"parent's"} email address<span style={{ color: "red" }}>*</span>
          </FormLabel>
          <Input name="parentEmail" value={formValues.parentEmail} onChange={handleInputChange} placeholder="Enter parent's email address" maxW="484px" h="48px" />
          <FormErrorMessage>{errors.parentEmail}</FormErrorMessage>
        </FormControl>

        <FormControl mt="24px" isRequired isInvalid={!!errors.passportPhoto}>
          <FormLabel fontSize="14px" fontWeight="600">
            Upload Your Passport Size Photograph (Note: This photograph will be used on your ID card.)
          </FormLabel>
          <CustomUploadFileInput
            disabled={false}
            fileUploadData={passportPhotoState as File}
            setFileUploadData={setPassportPhotoState as (data: File | undefined) => void}
            // acceptedFileTypes={}
            toastMessage=""
            handleDelete={() => {
              setPassportPhotoState(null);
            }}
            handleFileUpload={async (presignedUrls: GetS3PresignedUrlType) => {
              const imageUrl = await handleFileUploadOnSuccess(presignedUrls.presigned_url_for_aadhaar_back, passportPhotoState as File);
              setFormValues({ ...formValues, passportPhoto: imageUrl });
            }}
          />
          <FormErrorMessage>{errors.passportPhoto}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.graduationMonthYear} mt="24px">
          <FormLabel fontSize="14px" fontWeight="600">
            Select your graduation month and year<span style={{ color: "red" }}>*</span>
          </FormLabel>
          <Input type="month" name="graduationMonthYear" value={formValues.graduationMonthYear} onChange={handleInputChange} maxW="484px" h="48px" />
          <FormErrorMessage>{errors.graduationMonthYear}</FormErrorMessage>
        </FormControl>

        <Heading as="h6" mt={{ base: "24px", md: "24px" }}>
          Delivery Address
        </Heading>

        <FormControl isInvalid={!!errors.tshirtSize} mt="24px">
          <FormLabel fontSize="14px" fontWeight="600">
            T-shirt size<span style={{ color: "red" }}>*</span>
          </FormLabel>
          <Select name="tshirtSize" value={formValues.tshirtSize} onChange={handleInputChange} placeholder="Select your T-shirt size" maxW="484px" h="48px">
            <option value="XS">XS</option>
            <option value="S">S</option>
            <option value="M">M</option>
            <option value="L">L</option>
            <option value="XL">XL</option>
            <option value="XXL">XXL</option>
            <option value="XXXL">XXXL</option>
          </Select>
          <FormErrorMessage>{errors.tshirtSize}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.address} mt="24px">
          <FormLabel fontSize="14px" fontWeight="600">
            Flat, House no., Building, Apartment<span style={{ color: "red" }}>*</span>
          </FormLabel>
          <Input name="address" value={formValues.address} onChange={handleInputChange} maxW="484px" h="48px" />
          <FormErrorMessage>{errors.address}</FormErrorMessage>
        </FormControl>

        <FormControl mt="24px">
          <FormLabel fontSize="14px" fontWeight="600">
            Area, Street, Sector, Village
          </FormLabel>
          <Input name="areaStreet" value={formValues.areaStreet} onChange={handleInputChange} maxW="484px" h="48px" />
        </FormControl>

        <FormControl mt="24px">
          <FormLabel fontSize="14px" fontWeight="600">
            Landmark
          </FormLabel>
          <Input name="landmark" value={formValues.landmark} onChange={handleInputChange} maxW="484px" h="48px" />
        </FormControl>

        <FormControl isInvalid={!!errors.pincode} mt="24px">
          <FormLabel fontSize="14px" fontWeight="600">
            Pincode<span style={{ color: "red" }}>*</span>
          </FormLabel>
          <Input name="pincode" value={formValues.pincode} onChange={handleInputChange} placeholder="6 digits [0-9] PIN code" maxW="484px" h="48px" />
          <FormErrorMessage>{errors.pincode}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.townCity} mt="24px">
          <FormLabel fontSize="14px" fontWeight="600">
            Town/City<span style={{ color: "red" }}>*</span>
          </FormLabel>
          <Input name="townCity" value={formValues.townCity} onChange={handleInputChange} placeholder="Enter Town/City" maxW="484px" h="48px" />
          <FormErrorMessage>{errors.townCity}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.state} mt="24px">
          <FormLabel fontSize="14px" fontWeight="600">
            State<span style={{ color: "red" }}>*</span>
          </FormLabel>
          <Input name="state" value={formValues.state} onChange={handleInputChange} placeholder="Enter State" maxW="484px" h="48px" />
          <FormErrorMessage>{errors.state}</FormErrorMessage>
        </FormControl>
      </Box>

      <Box mt="24px" w="full" mb="100px">
        <Button isLoading={isSavinguserInfo} variant="primary" size="md" w="full" maxW="full" onClick={handleSubmit}>
          SUBMIT DETAIL
        </Button>
      </Box>
    </>
  );
};

export default OnboardingForm;
