/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Button, DrawerCloseButton, DrawerOverlay, Image, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Drawer, DrawerBody, DrawerHeader, DrawerContent } from "@chakra-ui/react";
import { DayTimeSlot, SlotTime } from "./AlumniSlotBooking/utils";
import { DateSelection } from "./AlumniSlotBooking/DateSelection";
import { TimeSelection } from "./AlumniSlotBooking/TimeSelection";
import NewAlumniCard from "../../V2/Alumni/NewAlumniCard";
import { useGetLevelupAlumnisSchedules } from "../../../api/utils/hooks/levelup/useGetLevelupAlumnis";
import { useMutation } from "react-query";
import { axiosErrorHandler } from "../../../api/utils/error";
import useSnackBar from "../general/SnackBar";
import { GetLevelupAlumniType, ProfileType } from "../../../api/schemas/schema";
import { updateProfile } from "../../../api/utils/api/v2/profile";
import { scheduleAlumniSession } from "../../../api/utils/api/v2/levelup_internal";

interface AlumniSlotBookingDrawerProps {
  onTextClick?: () => void;
  onCtaClick?: () => void;
  extendedText?: boolean;
  isOpen: boolean;
  onClose: () => void;
  selectedAlumni: GetLevelupAlumniType; //previously GetAlumniType
  setOpenSuccessModal: (value: boolean) => void;
  setAlmniName: (value: string) => void;
  profile?: ProfileType;
}

const AlumniSlotBookingDrawer = ({ isOpen, onClose, selectedAlumni, setOpenSuccessModal, setAlmniName, profile }: AlumniSlotBookingDrawerProps) => {
  const snackbar = useSnackBar();
  const [selectedDaySlot, setSelectedDaySlot] = useState<DayTimeSlot>();
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<SlotTime>();
  const [currentStep, setCurrentStep] = useState(1);
  console.log("selectedAlumni: ", selectedAlumni);
  //const { data: alumniSlots, refetch: alumniDataRefetch, isLoading: isAlumnisDataLoading } = useGetLevelupAlumnisSchedules(selectedAlumni.id);
  const [alumniSlots, setAlumniSlots] = useState<any>([]);
  const [isAlumnisDataLoading, setIsAlumnisDataLoading] = useState(false);
  const { mutate: updateProfileMutate } = useMutation(updateProfile, {
    onSuccess: () => {
      // profileRefetch();
      // setShowSelectCoursePreference(false);
    },
    onError: (error) => {
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong");
      }
    },
  });

  const { mutate: scheduleAlumniMeeting, isLoading: isSchedulingMeeting } = useMutation(scheduleAlumniSession, {
    onSuccess: (data) => {
      setOpenSuccessModal(true);
      setAlmniName(selectedAlumni.name);
      // update profile data
      if (data.token) {
        updateProfileMutate({ jwtToken: data.token });
      }
    },
    onError: (error) => {
      setOpenSuccessModal(false);
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong");
      }
    },
  });

  useEffect(() => {
    setSelectedDaySlot(undefined);
    setSelectedTimeSlot(undefined);
    //alumniDataRefetch();
  }, [selectedAlumni.id]);

  useEffect(() => {
    const firstAvailableDay = alumniSlots?.find((ds: any) => ds.slotTimes.length > 0);
    const firstAvailableSlot = firstAvailableDay?.slotTimes[0];
    setSelectedDaySlot(firstAvailableDay);
    setSelectedTimeSlot(firstAvailableSlot);
  }, [alumniSlots]);

  return (
    <Box>
      <Drawer
        placement={"bottom"}
        onClose={onClose}
        isOpen={isOpen}
        closeOnEsc={true} // dont allow to close
        closeOnOverlayClick={true}
      >
        <DrawerOverlay />
        <DrawerContent roundedTop="24px">
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="2px" width={{ base: "100%", lg: "75%" }} mx="auto" px={{ base: "2", lg: "0" }}>
            15 min One-on-One Connect
          </DrawerHeader>
          <DrawerBody>
            <Box display={"flex"} flexDir={{ base: "column", lg: "row" }} justifyContent={"space-between"} width={{ base: "100%", lg: "80%" }} margin={"auto"} maxW={"full"} minH={"50vh"}>
              <Box flex={1}>
                <NewAlumniCard
                  extendedText={true}
                  {...selectedAlumni}
                  hideButton={true}
                  onCtaClick={() => {
                    // setOpenAlumniModal(false);
                    // setOpenDrawer(true);
                  }}
                  currentStep={currentStep}
                  isAlumnisDataLoading={isAlumnisDataLoading}
                />
              </Box>
              <Box w="full" flex={1} display={{ base: "none", md: "flex" }} flexDir={"column"} gap="4">
                <Box w="full" maxW={{ lg: "80%" }} mx={"auto"} mr="10px" bg="#E5E5E5" p="4" rounded={"16px"} mb="4">
                  <Text fontSize={"18px"} mb={"12px"} fontWeight={600}>
                    Select date & time
                  </Text>
                  {alumniSlots && alumniSlots.length > 0 ? (
                    <>
                      <DateSelection
                        allSlots={alumniSlots}
                        selectedDaySlot={selectedDaySlot}
                        setSelectedDaySlot={(value: DayTimeSlot) => {
                          setSelectedDaySlot(value);
                          setSelectedTimeSlot(value.slotTimes[0]);
                        }}
                      />
                      <TimeSelection selectedDaySlot={selectedDaySlot} selectedTimeSlot={selectedTimeSlot} setSelectedTimeSlot={setSelectedTimeSlot} />
                    </>
                  ) : (
                    <Box display={"flex"} alignItems={"center"} justifyContent={"center"} flexDir={"column"} bg={"white"} py={8} px={4} rounded={"6px"} my={4} gap={2}>
                      <Image src="/img/calendar.svg" />
                      <Text textColor={"#6C6768"} fontSize={"14px"} textAlign={"center"} maxW={"242px"} mx={"auto"}>
                        Oops! It looks like our alumni&apos;s schedules are currently full. Check back later for available slots.
                      </Text>
                    </Box>
                  )}

                  <Box display="flex" flexDirection="row" gap="3" alignItems="center">
                    <Button
                      w={"full"}
                      variant="primary"
                      bg="#3E7BFA"
                      px="20px"
                      color="white"
                      isLoading={isSchedulingMeeting}
                      disabled={isSchedulingMeeting || alumniSlots?.length === 0 || selectedDaySlot?.slotTimes?.length === 0}
                      onClick={() => {
                        scheduleAlumniMeeting({
                          duration: selectedDaySlot?.duration ?? 0,
                          hostId: selectedAlumni.id,
                          sessionStartTime: (selectedTimeSlot && selectedTimeSlot.time && new Date(selectedTimeSlot?.time).toISOString()) ?? new Date().toISOString(),
                          type: "AlumniConnect",
                          clientUrl: window.location.href,
                          email: profile?.email ?? "",
                          name: profile?.name ?? "",
                          phone: profile?.phone_number ?? "",
                          slug: profile?.slug ?? "",
                        });
                      }}
                    >
                      Schedule
                    </Button>
                  </Box>
                </Box>
              </Box>

              <Box display={{ base: "block", md: "none" }}>
                {currentStep === 1 ? (
                  <Box display="flex" flexDirection="row" gap="3" alignItems="center" boxShadow={"0px -8px 32px 0px rgba(0, 0, 0, 0.10);"}>
                    <Button
                      w={"full"}
                      variant="primary"
                      bg="#3E7BFA"
                      px="20px"
                      color="white"
                      isLoading={isSchedulingMeeting}
                      disabled={isSchedulingMeeting}
                      onClick={() => {
                        setCurrentStep(2);
                      }}
                    >
                      Next
                    </Button>
                  </Box>
                ) : (
                  <Box w="full" maxW={{ lg: "80%" }} mx={"auto"} mr="10px" bg="#E5E5E5" p="4" rounded={"16px"} mb="4">
                    <DateSelection
                      allSlots={alumniSlots}
                      selectedDaySlot={selectedDaySlot}
                      setSelectedDaySlot={(value: DayTimeSlot) => {
                        setSelectedDaySlot(value);
                        setSelectedTimeSlot(value.slotTimes[0]);
                      }}
                    />
                    <TimeSelection selectedDaySlot={selectedDaySlot} selectedTimeSlot={selectedTimeSlot} setSelectedTimeSlot={setSelectedTimeSlot} />

                    <Box display="flex" flexDirection="row" gap="3" alignItems="center">
                      <Button
                        w={"full"}
                        variant="primary"
                        bg="#3E7BFA"
                        px="20px"
                        color="white"
                        isLoading={isSchedulingMeeting}
                        disabled={isSchedulingMeeting || alumniSlots?.length === 0 || selectedDaySlot?.slotTimes?.length === 0}
                        onClick={() => {
                          scheduleAlumniMeeting({
                            duration: selectedDaySlot?.duration ?? 0,
                            hostId: selectedAlumni.id,
                            sessionStartTime: (selectedTimeSlot && selectedTimeSlot.time && new Date(selectedTimeSlot?.time).toISOString()) ?? new Date().toISOString(),
                            type: "AlumniConnect",
                            clientUrl: window.location.href,
                            email: profile?.email ?? "",
                            name: profile?.name ?? "",
                            phone: profile?.phone_number ?? "",
                            slug: profile?.slug ?? "",
                          });
                        }}
                      >
                        Schedule
                      </Button>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default AlumniSlotBookingDrawer;
