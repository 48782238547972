import React, { useEffect, useState } from "react";
import { Badge, Box, Button, Flex, Image, Skeleton, useBreakpointValue, useDisclosure } from "@chakra-ui/react";
import { Menu as HamburgerIcon } from "../../../assets/icons/components";
import { useContext } from "react";
import { AppContext } from "../../../context/ApplicationContext";
import { Constants } from "../constants";
import { Logo, ModalTemplate, ModalTemplateV2 } from "../general";
import HamburgerMenu from "./HamburgerMenu";
import { HeaderRightSection } from "./HeaderRightSection";
import { useHistory, useLocation } from "react-router-dom";

import useSnackBar from "../general/SnackBar";
import { useMutation } from "react-query";
import { submitCounsellingRequest } from "../../../api/utils/api/v2/activity";
import BottomDrawer from "../V2/BottomDrawer";
import { useAllCounsellingStatus } from "../../../api/utils/hooks/useCounsellingStatus";
import { axiosErrorHandler } from "../../../api/utils/error";
import CounsellingDrawerContent from "../../V2/ModalContent/CounsellingDrawerContent";
import { COUNSELLING_TYPE } from "../../../api/schemas/schema";
import { isRequestCallbackDoneFn } from "../../../utils/user";
import { MobileHamburgerList } from "../../../interfaces/SidebarOption.interface";
import { ProgramProfileType } from "../../../enums/ProgramType.enum";
import HeaderMenuSection from "./HeaderMenuSection";
import CourseDropDownBtn from "./revamp/CourseDropDownBtn";
import HeaderRequestCallbackBtn from "./revamp/HeaderRequestCallbackBtn";
import HeaderReferAndEarnBtn from "./revamp/HeaderReferAndEarnBtn";
import { getHeader, HeaderResponse } from "../../../utils/header";
import { sendTrackingEvent } from "../utils/gtm";

interface Props {
  notifIconRequired?: boolean;
}

const bottomLabelsList: MobileHamburgerList[] = [
  {
    key: 1,
    title: "Referral Program",
    type: "hamburger-label",
    active: true,
    path: "/referral-program",
    badgeComponent: <Badge variant="red" label="New" />,
  },
  {
    key: 2,
    title: "Documents",
    type: "hamburger-label",
    active: true,
    path: "/batch-docs",
    isExternal: false,
  },
  {
    key: 3,
    title: "PAP",
    type: "hamburger-label",
    active: true,
    path: "/isa",
    link: "https://masaischool.com/fees",
    isExternal: true,
  },
];

const Header = () => {
  const history = useHistory();
  const snackbar = useSnackBar();
  const location = useLocation();
  const state = useContext(AppContext)[0];
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [headerData, setHeaderData] = useState<HeaderResponse>();
  const [isRequestCallbackDone, setIsRequestCallbackDone] = useState(false);
  const [openCounsellingSuccessModal, setOpenCounsellingSuccessModal] = React.useState(false);

  const { data: allCounsellingStatus, isLoading: isAllCounsellingStatusLoading, refetch: refetchAllCounsellingStatus } = useAllCounsellingStatus();
  const { mutate: submitCounsellingRequestMutate, isLoading: isSubmitCounsellingRequestLoading } = useMutation(submitCounsellingRequest, {
    onSuccess: () => {
      setIsRequestCallbackDone(true);
      setOpenCounsellingSuccessModal(true);
      setOpenDrawer(false);
      refetchAllCounsellingStatus();
    },
    onError: (error) => {
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong");
      }
    },
  });

  const isIITRoparCoursePrefered = state?.coursePreference === ProgramProfileType.IIT_ROPAR;
  const isIITMandiV2CoursePrefered = state.coursePreference === ProgramProfileType.IIT_MANDI_V2;
  const isIITGCoursePrefered = state?.coursePreference === ProgramProfileType.IIT_GUWAHATI;
  const isMasaiCoursePrefered = state?.coursePreference === ProgramProfileType.MASAI;
  const isIITMandiESCoursePrefered = state.coursePreference === ProgramProfileType.IIT_MANDI_ES;
  const isIITMandiBACoursePrefered = state.coursePreference === ProgramProfileType.IIT_MANDI_BA;
  const allApplicationPage = location.pathname === "/allapplications";

  const isOnbaordingPage = [
    "/onboarding/12",
    "/onboarding/14",
    "/iit-onboarding",
    "/iitg-onboarding",
    "/scholarship-onboarding",
    "/empowerHer-onboarding",
    "/iit-ropar-onboarding",
    "/minor-in-cse-onboarding",
  ].includes(location.pathname);

  const handleConsuellingRequest = () => {
    sendTrackingEvent({
      event: "d_header_callback",
      eventLabel: window.location.pathname,
    });
    if (state && state?.profileDataV2) {
      submitCounsellingRequestMutate({
        source: isMasaiCoursePrefered
          ? COUNSELLING_TYPE.REQUEST_CALLBACK
          : isIITGCoursePrefered
          ? COUNSELLING_TYPE.REQUEST_CALLBACK_IITG
          : isIITRoparCoursePrefered
          ? COUNSELLING_TYPE.REQUEST_CALLBACK_IIT_ROPAR
          : isIITMandiV2CoursePrefered
          ? COUNSELLING_TYPE.REQUEST_CALLBACK_IIT_MANDI_V2
          : isIITMandiESCoursePrefered
          ? COUNSELLING_TYPE.REQUEST_CALLBACK_IIT_MANDI_ES
          : isIITMandiBACoursePrefered
          ? COUNSELLING_TYPE.REQUEST_CALLBACK_IIT_MANDI_BA
          : COUNSELLING_TYPE.REQUEST_CALLBACK_IIT_MANDI,
      });
    } else {
      setOpenDrawer(true);
    }
  };

  useEffect(() => {
    const isIITGDashboard = state.coursePreference === ProgramProfileType.IIT_GUWAHATI;
    const isIITMandiDashboard = state.coursePreference === ProgramProfileType.IIT_MANDI;
    const isIITRoparDashboard = state.coursePreference === ProgramProfileType.IIT_ROPAR;
    const isIITMandiV2Dashboard = state.coursePreference === ProgramProfileType.IIT_MANDI_V2;
    const isIITMandiESDashboard = state.coursePreference === ProgramProfileType.IIT_MANDI_ES;
    const isIITMandiBADashboard = state.coursePreference === ProgramProfileType.IIT_MANDI_BA;
    // get counselling status with source as request callback
    if (allCounsellingStatus && allCounsellingStatus.length > 0) {
      const counsellingStatus = isRequestCallbackDoneFn(
        allCounsellingStatus,
        isIITGDashboard,
        isIITMandiDashboard,
        isIITRoparDashboard,
        false,
        false,
        false,
        isIITMandiV2Dashboard,
        isIITMandiESDashboard,
        isIITMandiBADashboard
      );
      if (counsellingStatus) {
        setIsRequestCallbackDone(true);
        if (window.location.search.includes("request_callback")) {
          window.history.replaceState({}, document.title, window.location.pathname);
        }
      }
    } else if (allCounsellingStatus && allCounsellingStatus.length === 0 && (isIITGDashboard || isIITMandiDashboard) && window.location.search.includes("request_callback")) {
      window.history.replaceState({}, document.title, window.location.pathname);
      setOpenDrawer(true);
    }
  }, [allCounsellingStatus, state, state.coursePreference]);

  useEffect(() => {
    if (state && state?.profileDataV2 && state?.profileDataV2?.program_profile_type) {
      const result = getHeader(location.pathname, state.profileDataV2.program_profile_type);
      setHeaderData(result);
    }
  }, [state.profileDataV2, location]);

  if (isAllCounsellingStatusLoading || !state || !state?.profileDataV2) return <Skeleton isLoaded={false} />;

  return (
    <>
      <Box
        as="nav"
        boxShadow="outer"
        position="fixed"
        left={0}
        top={0}
        bg="ms-primary"
        right={0}
        width={"full"}
        height={isIITRoparCoursePrefered || isIITMandiV2CoursePrefered || isIITMandiESCoursePrefered || isIITMandiBACoursePrefered ? `${Constants.headerHeight}` : `${Constants.headerHeight}`}
      >
        <Box maxW={1440} display={"flex"} mx={"auto"} px={{ base: "12px", md: "16px" }} align={"center"} h={"full"} gap={4} w={"full"}>
          <Flex align={"center"} maxW={{ base: "full", md: "300px" }} h={"full"} gap={{ base: 2, md: 8 }} w={"full"} justify={{ base: "space-between", md: "center" }}>
            <Flex gap={{ base: 3, md: 4 }} cursor={"pointer"}>
              <Flex
                align={"center"}
                justify={"center"}
                onClick={onOpen}
                display={{ base: isIITRoparCoursePrefered || isIITMandiV2CoursePrefered || isIITMandiESCoursePrefered || isIITMandiBACoursePrefered || isOnbaordingPage ? "none" : "flex", lg: "none" }}
                flexShrink={0}
              >
                <HamburgerIcon color="black" boxSize="24px" />
              </Flex>
              {(isIITRoparCoursePrefered || isIITMandiV2CoursePrefered || isIITMandiESCoursePrefered || isIITMandiBACoursePrefered) && !allApplicationPage ? (
                <Box
                  onClick={() => {
                    history.push("/");
                  }}
                >
                  <Image
                    objectFit={"contain"}
                    w={"82px"}
                    h={{ base: "52px", md: "64px" }}
                    src={
                      isIITRoparCoursePrefered
                        ? "https://masai-website-images.s3.ap-south-1.amazonaws.com/ropar_logo_cb5dfc22f7.webp"
                        : "https://masai-website-images.s3.ap-south-1.amazonaws.com/IIT_Mandi_Logo_8b61ff854f.png"
                    }
                    alt="IIT Ropar Logo"
                  />
                </Box>
              ) : (
                <Box
                  onClick={() => {
                    history.push("/");
                  }}
                >
                  <Logo />
                </Box>
              )}
            </Flex>
            {/* Course Dropdown */}
            <CourseDropDownBtn hideDropDown={!!headerData?.shouldHideCourseDropDown} />
          </Flex>

          <Flex
            flexShrink={{ base: 0, md: "initial" }}
            gap={{ base: isIITGCoursePrefered ? 4 : 2, md: 8 }}
            h={"full"}
            align={"center"}
            w={{ base: "auto", md: "full" }}
            flexDir={{ base: "row-reverse" }}
          >
            <HeaderRightSection username={state?.profileDataV2?.name} />
            <HeaderRequestCallbackBtn
              hideBtn={!!headerData?.shouldHideReqCallBtn}
              hideText={!!headerData?.shouldHideReqCallBtnText}
              handleConsuellingRequest={handleConsuellingRequest}
              isRequestCallbackDone={isRequestCallbackDone}
              isIITRoparPage={isIITRoparCoursePrefered}
              isSubmitCounsellingRequestLoading={isSubmitCounsellingRequestLoading}
            />
            <HeaderReferAndEarnBtn hideBtn={!!headerData?.shouldHideReferEarnBtn} hideText={false} />
            <HeaderMenuSection navbarLinks={headerData?.navLinks || []} hideLinks={!!headerData?.shouldHideHeaderLinks} isAdmin={state?.profileDataV2?.is_admin || false} />
          </Flex>
        </Box>
      </Box>
      <Box mt={"0px"} display={isOpen ? "block" : "none"} width={"100%"}>
        <HamburgerMenu isOpen={isOpen} onClose={onClose} labelsList={bottomLabelsList} />
      </Box>

      <BottomDrawer
        setOpenCounsellingSuccessModal={setOpenCounsellingSuccessModal}
        source={
          isMasaiCoursePrefered
            ? COUNSELLING_TYPE.REQUEST_CALLBACK
            : isIITGCoursePrefered
            ? COUNSELLING_TYPE.REQUEST_CALLBACK_IITG
            : isIITMandiV2CoursePrefered
            ? COUNSELLING_TYPE.REQUEST_CALLBACK_IIT_MANDI_V2
            : COUNSELLING_TYPE.REQUEST_CALLBACK_IIT_MANDI
        }
        openDrawer={openDrawer && isMobile ? true : false}
        setOpenDrawer={setOpenDrawer}
      />
      <ModalTemplate
        title=""
        isOpen={openDrawer && !isMobile ? true : false}
        onClose={() => {
          setOpenDrawer(false);
        }}
        showCloseButtonIcon={false}
      >
        <CounsellingDrawerContent
          setOpenCounsellingSuccessModal={setOpenCounsellingSuccessModal}
          source={
            isMasaiCoursePrefered
              ? COUNSELLING_TYPE.REQUEST_CALLBACK
              : isIITGCoursePrefered
              ? COUNSELLING_TYPE.REQUEST_CALLBACK_IITG
              : isIITMandiV2CoursePrefered
              ? COUNSELLING_TYPE.REQUEST_CALLBACK_IIT_MANDI_V2
              : COUNSELLING_TYPE.REQUEST_CALLBACK_IIT_MANDI
          }
          setOpenDrawer={setOpenDrawer}
        />
      </ModalTemplate>
      <ModalTemplateV2
        autoCloseInSeconds={5}
        closeOnOverlayClick={true}
        isOpen={openCounsellingSuccessModal}
        onClose={() => {
          console.log("hi");
          setOpenCounsellingSuccessModal(false);
          history.push("/");
        }}
        text="We have received your details, you will be receiving a call from our team shortly."
        extra={
          <>
            <Button
              onClick={() => {
                setOpenCounsellingSuccessModal(false);
                history.push("/");
              }}
              mt="28px"
              variant="secondary"
            >
              Close
            </Button>
          </>
        }
      />
    </>
  );
};

export default Header;
